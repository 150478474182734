@import '~bloko/common/styles/colors';

.container {
    display: flex;
    white-space: nowrap;
    text-align: center;

    &::before,
    &::after {
        position: relative;
        top: 50%;
        content: '';
        border-top: thin solid @color-gray-30;
        transform: translateY(50%);
        width: 100%;
    }
}

.wrapper {
    display: inline-block;
    padding: 0 12px;
}
