@import '~bloko/common/styles/colors';

.rounded-button-container {
    margin-bottom: -10px;
}

.rounded-button {
    display: inline-block;
    padding: 6px 12px;
    line-height: 140%;
    background: @color-white;
    border-radius: 18px;
    border: 1px solid @color-gray-20;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.rounded-button_active {
    background: @color-gray-20;
}
