@import '~bloko/common/styles/colors';

.link {
    cursor: pointer;
}

.pager {
    display: flex;
    justify-content: center;
    gap: 14px;

    position: relative;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    z-index: 1;
}

.dot {
    cursor: pointer;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    background: @color-gray-30;
}

.dot-active {
    background: @color-gray-80;
}

.hide {
    cursor: pointer;
}

.page-hidden {
    display: none;
}
