@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/spacing-base';

.modal-contents {
    max-width: 600px;
    margin: 0 10px;
    position: relative;
    color: @color-gray-80;

    @media @screen-gt-xs {
        padding: 0 40px;
        min-width: 480px;
    }
}

.modal-contents-free-widget-info {
    text-align: center;

    @media @screen-gt-xs {
        text-align: left;
    }
}

.free-widget-info-content {
    @media @screen-lt-s {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
    }
}

.arrows-container {
    position: relative;
    top: -50%;
    margin: 0 10px;
}

.bottom-buttons {
    display: flex;
    flex-direction: column-reverse;
    gap: @spacing-base;

    @media @screen-gt-xs {
        width: 100%;
        flex-direction: row;
        justify-content: flex-end;
        align-self: flex-end;
    }
}

.show-more-reviews-buttons {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;

    @media @screen-gt-xs {
        flex-direction: row;
        justify-content: flex-end;
        gap: 5px;
    }
}

.show-more-reviews-content {
    height: 100%;
    display: flex;
    align-items: center;
}

.answered {
    color: @color-gray-50;
}

.more-widgets-content {
    text-align: center;

    @media @screen-gt-xs {
        text-align: left;
    }
}
