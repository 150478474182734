@import '@hh.ru/design-tokens/build/less/base-variables';

.benefit-icon-container {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: @bloko-base-color-base-blue-60;
}

.benefit-icon-matched {
    color: @bloko-base-color-base-green-60;
}

.benefit-icon-text {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);

    border: 1px solid @bloko-base-color-base-gray-10;
    border-radius: @bloko-base-border-radius-1-x;
    padding: 2px 3px;
    background-color: @bloko-base-color-base-white;
    color: @bloko-base-color-base-black;

    white-space: nowrap;
}

.benefit-icon {
    position: absolute;
    line-height: 0;
    width: 24px;
    height: 24px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
