@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.header {
    color: @color-gray-60;
    text-align: center;
}

.images {
    display: flex;
    justify-content: space-between;
}

.image {
    width: 125px;
    height: 60px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
}

.image_sberbank {
    background-image: url('./sber.svg');
}

.image_it-consalting {
    background-image: url('./it.png');
}

.image_yandex {
    background-image: url('./yndx.svg');
}

.image_rolf {
    background-image: url('./rolf.png');
}

.image_delta-contact {
    background-image: url('./delta.svg');
}

.image_miratorg {
    background-image: url('./miratorg.png');
}

.image_sportmaster {
    background-image: url('./srtmaster.svg');
}

.image_mirafox {
    background-image: url('./fox.svg');
}
