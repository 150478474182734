@import '@hh.ru/design-tokens/build/less/base-variables';

.icon-wrapper {
    transform: rotate(-253deg);
    transform-origin: 50% 50%;
}

.inactive-color {
    color: @bloko-base-color-base-gray-20;
}
