@import '~bloko/common/styles/media/_screen';

.logo {
    display: flex;
    align-items: center;
    justify-content: left;
    overflow: hidden;
    width: 92px;

    @media @screen-s {
        width: 80px;
    }
}

.image {
    max-width: 100%;
    object-fit: contain;
}

.rounded {
    justify-content: center;
    border-radius: 50%;

    .link {
        width: 100%;
        height: 100%;
    }

    .image {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }
}
