@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.container {
    background: @color-gray-10;
    padding: 10px;
    border-radius: 4px;

    @media @screen-gt-xs {
        padding-right: 80px;
    }
}
