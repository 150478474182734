@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.container {
    background: @color-gray-10;
    padding: 24px 16px;
    border-radius: 8px;

    @media @screen-gt-s {
        padding: 40px;
    }
}

.pages {
    display: none;

    @media @screen-gt-s {
        display: block;
    }
}
