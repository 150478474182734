@import '~bloko/common/styles/colors';

.top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 12px;
}

.top-rating {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
}

.top-rating-date {
    margin-inline-start: 4px;
}

.top-line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.total-rating {
    margin-right: 8px;
}

.review-stars {
    margin-right: 16px;
}

.review-date {
    color: @color-gray-50;
}
