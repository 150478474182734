@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.header {
    color: @color-blue-60;
    margin-bottom: 3px;
}

.warning {
    color: @color-red-60;
}
