@import '~bloko/common/styles/media/_screen';

.full-width {
    width: 100%;
}

.lock {
    background-image: url('./lock.svg');
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
}

.content {
    min-width: 100%;

    @media @screen-gt-xs {
        min-width: 620px;
    }
}

.actions {
    display: flex;
    gap: 5px;
    justify-content: flex-end;

    flex-direction: column-reverse;

    @media @screen-gt-xs {
        flex-direction: row;
    }
}
