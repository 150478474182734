.container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 12px;
}

.item {
    flex: 1;
    width: 290px;
    min-width: 290px;
    height: 230px;
    max-height: 230px;
}
