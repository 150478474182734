@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.container {
    display: flex;
    align-items: center;
    gap: 16px;

    @media @screen-gt-s {
        gap: 22px;
        max-width: 300px;
    }
}

.title {
    display: none;

    @media @screen-gt-s {
        display: block;
    }
}

.subtitle {
    display: none;

    @media @screen-gt-s {
        display: block;
    }
}

.small-title {
    @media @screen-gt-s {
        display: none;
    }
}
