@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~styles/vars';

.root {
    @media @screen-gt-m {
        width: 400px;
        max-height: 120px;
        padding: 24px;
        box-shadow: @widget-box-shadow;
        border-radius: 10px;
        background-position: right bottom;
        background-repeat: no-repeat;
    }
}
