@import '~bloko/common/styles/media/_screen';

.root {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    flex-direction: column;
    gap: 16px;

    @media @screen-gt-xs {
        flex-direction: row;
    }
}
