@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.screen {
    display: flex;
    align-items: center;
    justify-content: center;

    background: @color-white;

    @media @screen-gt-xs {
        left: 20px;
        right: 20px;
        min-width: 500px;
    }
}

.center-wrapper {
    width: 270px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text {
    margin: 32px 0;
}
