.benefit {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.benefit-text {
    margin-left: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 12px;
    height: 100%;
    padding: 12px;
    box-sizing: border-box;
}

.tags {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}
