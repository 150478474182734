@import '~bloko/common/styles/colors';

.small-widget {
    display: flex;
    align-items: center;
}

.rating-container {
    margin-right: 8px;
}

.white-container {
    color: @color-white;
}

.stars-container {
    display: flex;
    width: 116px;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;
}

.no-reviews {
    color: @color-gray-50;
}
