@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';
@import '~styles/vars';

.wrapper {
    background: @color-white;
    box-shadow: @widget-box-shadow;
    border-radius: @rounded-corner-default * 2;
    box-sizing: border-box;
    height: 226px;
    padding: 0 24px;
    width: 270px;
    cursor: pointer;

    @media @screen-gt-xs {
        padding: 0 24px;
        width: 500px;
    }
}

.answered {
    color: @color-gray-50;
}
