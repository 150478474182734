@import '~bloko/common/styles/colors';

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 12px;
}

.button-icon {
    display: inline-block;
    position: relative;
    top: 2px;
}

.container {
    display: flex;
    gap: 8px;
    width: 100%;
}

.chip-icon {
    background-size: cover;
    width: 14px;
    height: 14px;
    margin-right: 8px;
}

.chip-icon_white {
    background-position: -100%;
}

.chip-icon_yes {
    background-image: url('./images/recommend-yes.svg');
}

.chip-icon_no {
    background-image: url('./images/recommend-no.svg');
}
