@import '~bloko/common/styles/media/_screen';

.root {
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    width: 100%;
    padding-bottom: 40px;

    text-align: left;

    @media @screen-gt-s {
        align-items: center;
        flex-direction: column;

        width: 290px;
    }
}

.image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;

    width: 85px;
    height: 55px;
    margin-right: 30px;

    flex-shrink: 0;

    @media @screen-gt-xs {
        margin-right: 40px;
        width: 220px;
        height: 100px;
    }

    @media @screen-gt-s {
        margin-right: 0;
        margin-bottom: 40px;
    }
}

.image_analyze {
    background-image: url('./analyze_feedback.svg');
}

.image_answer {
    background-image: url('./answer_reviews_mini.svg');

    @media @screen-gt-s {
        background-image: url('./answer_reviews.svg');
    }
}

.image_create {
    background-image: url('./create_good_impression.svg');
}
