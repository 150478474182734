@import '~bloko/common/styles/media/_screen';

.container {
    display: flex;
    gap: 16px;

    align-items: stretch;
}

.advice {
    display: flex;
    flex: 1;
}

.advice-text {
    display: flex;
    align-items: center;
}

.last-advice {
    display: none;

    @media @screen-gt-m {
        display: flex;
    }
}

.more-advices {
    margin-left: 8px;
}
