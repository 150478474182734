@import '~bloko/common/styles/colors';

.list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 8px;
}

.tag-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 8px;
}

.tag {
    background: @color-gray-10;
    border-radius: 4px;
    padding: 2px 8px;
}

.tag_good {
    background: @color-green-10;
}

.tag_bad {
    background: @color-red-10;
}
