.link-to-settings {
    display: inline-flex;
    align-items: center;
}

.link-to-settings_stretched {
    width: 100%;
}

.blocked-activator {
    display: inline-flex;
    align-items: center;
    width: 100%;
    pointer-events: none;
}

.hover-tip-text {
    text-align: left;
}
