@import '~bloko/common/styles/colors';

.root {
    &:not(:last-child) {
        border-bottom: 1px solid @color-gray-20;
    }
}

.title {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.description {
    transition:
        height 0.2s ease,
        opacity 0.2s ease;
    height: 0;
    overflow: hidden;
}

.icon {
    margin-left: auto;
    transition: transform 0.2s ease;
}

.icon_expanded {
    transform: rotate(180deg);
}
