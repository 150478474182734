@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.cards {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media @screen-gt-s {
        flex-direction: row;
    }
}
