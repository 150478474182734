@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.card {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    height: 100%;
}

.card-header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 12px 12px 0;
    box-sizing: border-box;
}

.card-header-did-not-work-here {
    padding: 12px 12px 0;
    box-sizing: border-box;
}

.card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: stretch;
    padding-top: 24px;
    box-sizing: border-box;
}

.employer-card {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    box-shadow:
        0 2px 10px fade(@color-gray-80, 8),
        inset 0 0.5px 1px fade(@color-gray-80, 6);
    border-radius: 8px;
    padding-bottom: 16px;
    width: 100%;
    min-height: 186px;
}

.employer-card_light {
    box-shadow: none;
    border-radius: unset;
    padding: 0;
    height: 80px;
}

.employer-card-content {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.employer-card-text-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 16px 0;
}

.employer-card-logo {
    padding: 12px;
}

.employer-card-footer {
    padding: 16px 16px 0;
}
