@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.arrow {
    position: absolute;
    top: 50%;
    margin-top: -8px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: @color-white;
    box-shadow: 0 2px 6px fade(@color-gray-80, 25%);
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @media @screen-gt-xs {
        display: flex;
    }
}

.arrow_right {
    right: 0;
    margin-right: -20px;
}

.arrow_left {
    left: 0;
    margin-left: -20px;
}
