@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.root {
    display: flex;
    background: linear-gradient(272.39deg, @color-blue-40 2%, @color-blue-60 98%);
    border-radius: 24px;

    padding: 40px 32px;

    @media @screen-gt-xs {
        padding: 72px 48px;
    }

    @media @screen-gt-s {
        padding: 72px 80px;
    }
}

.header,
.sub-header {
    color: @color-white;
}

.images {
    background-image: url('./header_pictures.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 390px;
    height: 240px;
    flex-shrink: 0;

    @media @screen-lt-m {
        display: none;
    }
}
