@import '~bloko/common/styles/media/_screen';

.container {
    display: flex;
    position: relative;
}

.info {
    @media @screen-gt-m {
        max-width: 640px;
    }
}

.image {
    display: none;
    width: 258px;
    height: 521px;

    background-image: url('./iphone-14-pro.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;

    position: absolute;
    right: 100px;
    top: 20px;

    @media @screen-gt-m {
        display: block;
    }
}
