.star {
    display: inline-block;
    background-image: url('./star.svg');
    background-size: cover;
    width: 20px;
    height: 20px;
}

.star-magritte {
    background-image: url('./star-magritte.svg');
}

.star_micro {
    width: 14px;
    height: 14px;
}

.star_small {
    width: 16px;
    height: 16px;
}

.star_big {
    width: 24px;
    height: 24px;
}

.star_half-filled {
    background-position: -100%;
}

.star_filled {
    background-position: -200%;
}

.star_inactive {
    background-position: -300%;
}

.star_gray-filled {
    background-position: -400%;
}
