@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.container {
    max-width: 600px;
    margin: 0 10px;

    @media @screen-gt-xs {
        padding: 0 40px;
        min-width: 480px;
    }
}

.container_content {
    @media @screen-gt-xs {
        min-height: 50vh;
    }
}

.benefit {
    display: flex;
    align-items: center;
}

.benefit-title {
    margin-left: 12px;
}

.sub-header {
    color: @color-gray-50;
}
