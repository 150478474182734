@import '~bloko/common/styles/media/_screen';

.container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;

    @media @screen-gt-s {
        flex-direction: row;
    }
}
