@import '~bloko/common/styles/colors';

.wrapper {
    position: relative;
}

.container {
    transform: rotate(-90deg);
}

.track {
    stroke: @color-gray-20;
}

.indicator {
    stroke-linecap: round;
    stroke: @color-orange-40;
}

.indicator-full {
    stroke: @color-green-40;
}

.children {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
