/* stylelint-disable selector-list-comma-newline-after */
html,
body,
div,
span,
table,
tbody,
thead,
tfoot,
tr,
td,
hr,
br,
p,
em,
abbr,
strong,
small,
h1,
h2,
h3,
h4,
h5,
h6,
img,
sub,
sup,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
iframe,
object,
a,
article,
aside,
audio,
bdi,
canvas,
data,
datalist,
details,
figcaption,
figure,
footer,
header,
main,
mark,
meter,
nav,
output,
progress,
section,
summary,
time,
video {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  background: transparent;
}
/* stylelint-enable */
a {
  color: #468FFD;
  text-decoration: none;
  border: 0;
  background: transparent;
  font-size: inherit;
  outline: none;
}
a:hover,
a:focus,
a:active {
  color: #FE725F;
  outline: none;
}
/**
 * Do not edit directly
 * Generated on Mon, 19 Feb 2024 06:49:45 GMT
 */
/* stylelint-disable selector-nested-pattern */
/* stylelint-enable */
@keyframes abstract-link-loading {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -5px;
  }
}
/* stylelint-disable value-keyword-case */
/* stylelint-enable */
/* stylelint-disable-next-line selector-class-pattern */
/**
 * Do not edit directly
 * Generated on Mon, 19 Feb 2024 06:49:45 GMT
 */
:root {
  --bloko-color-button-default-background-filled-default-v0-12-2: #F4F6FB;
  --bloko-color-button-default-background-filled-disabled-v0-12-2: #F4F6FB80;
  --bloko-color-button-default-background-filled-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-default-background-filled-active-v0-12-2: #E2E2E2;
  --bloko-color-button-default-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-default-background-outlined-disabled-v0-12-2: #FFFFFF00;
  --bloko-color-button-default-background-outlined-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-default-background-outlined-active-v0-12-2: #E2E2E2;
  --bloko-color-button-default-background-flat-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-default-background-flat-disabled-v0-12-2: #FFFFFF00;
  --bloko-color-button-default-background-flat-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-default-background-flat-active-v0-12-2: #E2E2E2;
  --bloko-color-button-default-border-filled-default-v0-12-2: #AABBCA;
  --bloko-color-button-default-border-filled-disabled-v0-12-2: #AABBCA80;
  --bloko-color-button-default-border-filled-hover-v0-12-2: #AABBCA;
  --bloko-color-button-default-border-filled-active-v0-12-2: #AABBCA;
  --bloko-color-button-default-border-outlined-default-v0-12-2: #AABBCA;
  --bloko-color-button-default-border-outlined-disabled-v0-12-2: #AABBCA80;
  --bloko-color-button-default-border-outlined-hover-v0-12-2: #AABBCA;
  --bloko-color-button-default-border-outlined-active-v0-12-2: #AABBCA;
  --bloko-color-button-default-border-flat-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-default-border-flat-disabled-v0-12-2: #FFFFFF00;
  --bloko-color-button-default-border-flat-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-default-border-flat-active-v0-12-2: #E2E2E2;
  --bloko-color-button-default-typography-filled-default-v0-12-2: #2A3137;
  --bloko-color-button-default-typography-filled-disabled-v0-12-2: #2A313780;
  --bloko-color-button-default-typography-filled-hover-v0-12-2: #2A3137;
  --bloko-color-button-default-typography-filled-active-v0-12-2: #2A3137;
  --bloko-color-button-default-typography-outlined-default-v0-12-2: #2A3137;
  --bloko-color-button-default-typography-outlined-disabled-v0-12-2: #2A313780;
  --bloko-color-button-default-typography-outlined-hover-v0-12-2: #2A3137;
  --bloko-color-button-default-typography-outlined-active-v0-12-2: #2A3137;
  --bloko-color-button-default-typography-flat-default-v0-12-2: #2A3137;
  --bloko-color-button-default-typography-flat-disabled-v0-12-2: #2A313780;
  --bloko-color-button-default-typography-flat-hover-v0-12-2: #2A3137;
  --bloko-color-button-default-typography-flat-active-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-filled-default-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-filled-disabled-v0-12-2: #2A313780;
  --bloko-color-button-default-icon-filled-hover-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-filled-active-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-outlined-default-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-outlined-disabled-v0-12-2: #2A313780;
  --bloko-color-button-default-icon-outlined-hover-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-outlined-active-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-flat-default-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-flat-disabled-v0-12-2: #2A313780;
  --bloko-color-button-default-icon-flat-hover-v0-12-2: #2A3137;
  --bloko-color-button-default-icon-flat-active-v0-12-2: #2A3137;
  --bloko-color-button-primary-background-filled-default-v0-12-2: #0070FF;
  --bloko-color-button-primary-background-filled-disabled-v0-12-2: #0070FF80;
  --bloko-color-button-primary-background-filled-hover-v0-12-2: #2B7FFF;
  --bloko-color-button-primary-background-filled-active-v0-12-2: #0070FF;
  --bloko-color-button-primary-background-outlined-default-v0-12-2: #ffffff00;
  --bloko-color-button-primary-background-outlined-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-primary-background-outlined-hover-v0-12-2: #2B7FFF;
  --bloko-color-button-primary-background-outlined-active-v0-12-2: #0070FF;
  --bloko-color-button-primary-background-flat-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-primary-background-flat-disabled-v0-12-2: #FFFFFF00;
  --bloko-color-button-primary-background-flat-hover-v0-12-2: #2B7FFF;
  --bloko-color-button-primary-background-flat-active-v0-12-2: #0070FF;
  --bloko-color-button-primary-border-filled-default-v0-12-2: #0070FF;
  --bloko-color-button-primary-border-filled-disabled-v0-12-2: #0070FF00;
  --bloko-color-button-primary-border-filled-hover-v0-12-2: #2B7FFF;
  --bloko-color-button-primary-border-filled-active-v0-12-2: #0070FF;
  --bloko-color-button-primary-border-outlined-default-v0-12-2: #0070FF;
  --bloko-color-button-primary-border-outlined-disabled-v0-12-2: #0070FF80;
  --bloko-color-button-primary-border-outlined-hover-v0-12-2: #2B7FFF;
  --bloko-color-button-primary-border-outlined-active-v0-12-2: #0070FF;
  --bloko-color-button-primary-border-flat-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-primary-border-flat-disabled-v0-12-2: #FFFFFF00;
  --bloko-color-button-primary-border-flat-hover-v0-12-2: #2B7FFF;
  --bloko-color-button-primary-border-flat-active-v0-12-2: #0070FF;
  --bloko-color-button-primary-typography-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-primary-typography-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-outlined-default-v0-12-2: #0070FF;
  --bloko-color-button-primary-typography-outlined-disabled-v0-12-2: #0070FF80;
  --bloko-color-button-primary-typography-outlined-hover-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-outlined-active-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-flat-default-v0-12-2: #0070FF;
  --bloko-color-button-primary-typography-flat-disabled-v0-12-2: #0070FF80;
  --bloko-color-button-primary-typography-flat-hover-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-flat-active-v0-12-2: #ffffff;
  --bloko-color-button-primary-icon-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-primary-icon-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-primary-icon-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-primary-icon-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-primary-icon-outlined-default-v0-12-2: #0070FF;
  --bloko-color-button-primary-icon-outlined-disabled-v0-12-2: #0070FF80;
  --bloko-color-button-primary-icon-outlined-hover-v0-12-2: #ffffff;
  --bloko-color-button-primary-icon-outlined-active-v0-12-2: #ffffff;
  --bloko-color-button-primary-icon-flat-default-v0-12-2: #0070FF;
  --bloko-color-button-primary-icon-flat-disabled-v0-12-2: #0070FF80;
  --bloko-color-button-primary-icon-flat-hover-v0-12-2: #ffffff;
  --bloko-color-button-primary-icon-flat-active-v0-12-2: #ffffff;
  --bloko-color-button-secondary-background-filled-default-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-background-filled-disabled-v0-12-2: #76ADFE80;
  --bloko-color-button-secondary-background-filled-hover-v0-12-2: #8CBBFF;
  --bloko-color-button-secondary-background-filled-active-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-background-outlined-default-v0-12-2: #ffffff00;
  --bloko-color-button-secondary-background-outlined-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-secondary-background-outlined-hover-v0-12-2: #8CBBFF;
  --bloko-color-button-secondary-background-outlined-active-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-background-flat-default-v0-12-2: #ffffff00;
  --bloko-color-button-secondary-background-flat-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-secondary-background-flat-hover-v0-12-2: #8CBBFF;
  --bloko-color-button-secondary-background-flat-active-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-border-filled-default-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-border-filled-disabled-v0-12-2: #76ADFE00;
  --bloko-color-button-secondary-border-filled-hover-v0-12-2: #8CBBFF;
  --bloko-color-button-secondary-border-filled-active-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-border-outlined-default-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-border-outlined-disabled-v0-12-2: #76ADFE80;
  --bloko-color-button-secondary-border-outlined-hover-v0-12-2: #8CBBFF;
  --bloko-color-button-secondary-border-outlined-active-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-border-flat-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-secondary-border-flat-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-secondary-border-flat-hover-v0-12-2: #8CBBFF;
  --bloko-color-button-secondary-border-flat-active-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-typography-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-secondary-typography-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-secondary-typography-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-secondary-typography-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-secondary-typography-outlined-default-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-typography-outlined-disabled-v0-12-2: #76ADFE80;
  --bloko-color-button-secondary-typography-outlined-hover-v0-12-2: #ffffff;
  --bloko-color-button-secondary-typography-outlined-active-v0-12-2: #ffffff;
  --bloko-color-button-secondary-typography-flat-default-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-typography-flat-disabled-v0-12-2: #76ADFE80;
  --bloko-color-button-secondary-typography-flat-hover-v0-12-2: #ffffff;
  --bloko-color-button-secondary-typography-flat-active-v0-12-2: #ffffff;
  --bloko-color-button-secondary-icon-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-secondary-icon-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-secondary-icon-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-secondary-icon-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-secondary-icon-outlined-default-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-icon-outlined-disabled-v0-12-2: #76ADFE80;
  --bloko-color-button-secondary-icon-outlined-hover-v0-12-2: #ffffff;
  --bloko-color-button-secondary-icon-outlined-active-v0-12-2: #ffffff;
  --bloko-color-button-secondary-icon-flat-default-v0-12-2: #76ADFE;
  --bloko-color-button-secondary-icon-flat-disabled-v0-12-2: #76ADFE80;
  --bloko-color-button-secondary-icon-flat-hover-v0-12-2: #ffffff;
  --bloko-color-button-secondary-icon-flat-active-v0-12-2: #ffffff;
  --bloko-color-button-warning-background-filled-default-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-background-filled-disabled-v0-12-2: #FF4D3A80;
  --bloko-color-button-warning-background-filled-hover-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-background-filled-active-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-background-outlined-default-v0-12-2: #ffffff00;
  --bloko-color-button-warning-background-outlined-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-warning-background-outlined-hover-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-background-outlined-active-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-background-flat-default-v0-12-2: #ffffff00;
  --bloko-color-button-warning-background-flat-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-warning-background-flat-hover-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-background-flat-active-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-border-filled-default-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-border-filled-disabled-v0-12-2: #FF4D3A00;
  --bloko-color-button-warning-border-filled-hover-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-border-filled-active-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-border-outlined-default-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-border-outlined-disabled-v0-12-2: #FF4D3A80;
  --bloko-color-button-warning-border-outlined-hover-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-border-outlined-active-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-border-flat-default-v0-12-2: #ffffff00;
  --bloko-color-button-warning-border-flat-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-warning-border-flat-hover-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-border-flat-active-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-typography-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-warning-typography-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-warning-typography-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-warning-typography-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-warning-typography-outlined-default-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-typography-outlined-disabled-v0-12-2: #FF4D3A80;
  --bloko-color-button-warning-typography-outlined-hover-v0-12-2: #ffffff;
  --bloko-color-button-warning-typography-outlined-active-v0-12-2: #ffffff;
  --bloko-color-button-warning-typography-flat-default-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-typography-flat-disabled-v0-12-2: #FF4D3A80;
  --bloko-color-button-warning-typography-flat-hover-v0-12-2: #ffffff;
  --bloko-color-button-warning-typography-flat-active-v0-12-2: #ffffff;
  --bloko-color-button-warning-icon-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-warning-icon-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-warning-icon-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-warning-icon-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-warning-icon-outlined-default-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-icon-outlined-disabled-v0-12-2: #FF4D3A80;
  --bloko-color-button-warning-icon-outlined-hover-v0-12-2: #ffffff;
  --bloko-color-button-warning-icon-outlined-active-v0-12-2: #ffffff;
  --bloko-color-button-warning-icon-flat-default-v0-12-2: #FF4D3A;
  --bloko-color-button-warning-icon-flat-disabled-v0-12-2: #FF4D3A80;
  --bloko-color-button-warning-icon-flat-hover-v0-12-2: #ffffff;
  --bloko-color-button-warning-icon-flat-active-v0-12-2: #ffffff;
  --bloko-color-button-success-background-filled-default-v0-12-2: #0DC267;
  --bloko-color-button-success-background-filled-disabled-v0-12-2: #0DC26780;
  --bloko-color-button-success-background-filled-hover-v0-12-2: #5BCF83;
  --bloko-color-button-success-background-filled-active-v0-12-2: #0DC267;
  --bloko-color-button-success-background-outlined-default-v0-12-2: #ffffff00;
  --bloko-color-button-success-background-outlined-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-success-background-outlined-hover-v0-12-2: #5BCF83;
  --bloko-color-button-success-background-outlined-active-v0-12-2: #0DC267;
  --bloko-color-button-success-background-flat-default-v0-12-2: #ffffff00;
  --bloko-color-button-success-background-flat-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-success-background-flat-hover-v0-12-2: #5BCF83;
  --bloko-color-button-success-background-flat-active-v0-12-2: #0DC267;
  --bloko-color-button-success-border-filled-default-v0-12-2: #0DC267;
  --bloko-color-button-success-border-filled-disabled-v0-12-2: #0DC26700;
  --bloko-color-button-success-border-filled-hover-v0-12-2: #5BCF83;
  --bloko-color-button-success-border-filled-active-v0-12-2: #0DC267;
  --bloko-color-button-success-border-outlined-default-v0-12-2: #0DC267;
  --bloko-color-button-success-border-outlined-disabled-v0-12-2: #0DC26780;
  --bloko-color-button-success-border-outlined-hover-v0-12-2: #5BCF83;
  --bloko-color-button-success-border-outlined-active-v0-12-2: #0DC267;
  --bloko-color-button-success-border-flat-default-v0-12-2: #ffffff00;
  --bloko-color-button-success-border-flat-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-success-border-flat-hover-v0-12-2: #5BCF83;
  --bloko-color-button-success-border-flat-active-v0-12-2: #0DC267;
  --bloko-color-button-success-typography-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-success-typography-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-success-typography-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-success-typography-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-success-typography-outlined-default-v0-12-2: #0DC267;
  --bloko-color-button-success-typography-outlined-disabled-v0-12-2: #0DC26780;
  --bloko-color-button-success-typography-outlined-hover-v0-12-2: #ffffff;
  --bloko-color-button-success-typography-outlined-active-v0-12-2: #ffffff;
  --bloko-color-button-success-typography-flat-default-v0-12-2: #0DC267;
  --bloko-color-button-success-typography-flat-disabled-v0-12-2: #0DC26780;
  --bloko-color-button-success-typography-flat-hover-v0-12-2: #ffffff;
  --bloko-color-button-success-typography-flat-active-v0-12-2: #ffffff;
  --bloko-color-button-success-icon-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-success-icon-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-success-icon-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-success-icon-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-success-icon-outlined-default-v0-12-2: #0DC267;
  --bloko-color-button-success-icon-outlined-disabled-v0-12-2: #0DC26780;
  --bloko-color-button-success-icon-outlined-hover-v0-12-2: #ffffff;
  --bloko-color-button-success-icon-outlined-active-v0-12-2: #ffffff;
  --bloko-color-button-success-icon-flat-default-v0-12-2: #0DC267;
  --bloko-color-button-success-icon-flat-disabled-v0-12-2: #0DC26780;
  --bloko-color-button-success-icon-flat-hover-v0-12-2: #ffffff;
  --bloko-color-button-success-icon-flat-active-v0-12-2: #ffffff;
  --bloko-color-button-inversed-background-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-inversed-background-filled-disabled-v0-12-2: #ffffff80;
  --bloko-color-button-inversed-background-filled-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-inversed-background-filled-active-v0-12-2: #EEF1F7;
  --bloko-color-button-inversed-background-outlined-default-v0-12-2: #ffffff00;
  --bloko-color-button-inversed-background-outlined-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-inversed-background-outlined-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-inversed-background-outlined-active-v0-12-2: #EEF1F7;
  --bloko-color-button-inversed-background-flat-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-inversed-background-flat-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-inversed-background-flat-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-inversed-background-flat-active-v0-12-2: #EEF1F7;
  --bloko-color-button-inversed-border-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-inversed-border-filled-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-inversed-border-filled-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-inversed-border-filled-active-v0-12-2: #EEF1F7;
  --bloko-color-button-inversed-border-outlined-default-v0-12-2: #ffffff;
  --bloko-color-button-inversed-border-outlined-disabled-v0-12-2: #ffffff80;
  --bloko-color-button-inversed-border-outlined-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-inversed-border-outlined-active-v0-12-2: #EEF1F7;
  --bloko-color-button-inversed-border-flat-default-v0-12-2: #ffffff00;
  --bloko-color-button-inversed-border-flat-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-inversed-border-flat-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-inversed-border-flat-active-v0-12-2: #EEF1F7;
  --bloko-color-button-inversed-typography-filled-default-v0-12-2: #2A3137;
  --bloko-color-button-inversed-typography-filled-disabled-v0-12-2: #2A3137CC;
  --bloko-color-button-inversed-typography-filled-hover-v0-12-2: #2A3137;
  --bloko-color-button-inversed-typography-filled-active-v0-12-2: #2A3137;
  --bloko-color-button-inversed-typography-outlined-default-v0-12-2: #ffffff;
  --bloko-color-button-inversed-typography-outlined-disabled-v0-12-2: #ffffff80;
  --bloko-color-button-inversed-typography-outlined-hover-v0-12-2: #2A3137;
  --bloko-color-button-inversed-typography-outlined-active-v0-12-2: #2A3137;
  --bloko-color-button-inversed-typography-flat-default-v0-12-2: #ffffff;
  --bloko-color-button-inversed-typography-flat-disabled-v0-12-2: #ffffff80;
  --bloko-color-button-inversed-typography-flat-hover-v0-12-2: #2A3137;
  --bloko-color-button-inversed-typography-flat-active-v0-12-2: #2A3137;
  --bloko-color-button-inversed-icon-filled-default-v0-12-2: #2A3137;
  --bloko-color-button-inversed-icon-filled-disabled-v0-12-2: #2A3137CC;
  --bloko-color-button-inversed-icon-filled-hover-v0-12-2: #2A3137;
  --bloko-color-button-inversed-icon-filled-active-v0-12-2: #2A3137;
  --bloko-color-button-inversed-icon-outlined-default-v0-12-2: #ffffff;
  --bloko-color-button-inversed-icon-outlined-disabled-v0-12-2: #ffffff80;
  --bloko-color-button-inversed-icon-outlined-hover-v0-12-2: #2A3137;
  --bloko-color-button-inversed-icon-outlined-active-v0-12-2: #2A3137;
  --bloko-color-button-inversed-icon-flat-default-v0-12-2: #ffffff;
  --bloko-color-button-inversed-icon-flat-disabled-v0-12-2: #ffffff80;
  --bloko-color-button-inversed-icon-flat-hover-v0-12-2: #2A3137;
  --bloko-color-button-inversed-icon-flat-active-v0-12-2: #2A3137;
  --bloko-color-button-social-vk-background-filled-color-default-v0-12-2: #0077FF;
  --bloko-color-button-social-vk-background-filled-color-hover-v0-12-2: #0071F2;
  --bloko-color-button-social-vk-background-filled-color-active-v0-12-2: #006BE5;
  --bloko-color-button-social-vk-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-vk-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-vk-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-vk-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-vk-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-vk-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-vk-border-filled-color-default-v0-12-2: #0077FF00;
  --bloko-color-button-social-vk-border-filled-color-hover-v0-12-2: #0077FF00;
  --bloko-color-button-social-vk-border-filled-color-active-v0-12-2: #0077FF00;
  --bloko-color-button-social-vk-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-vk-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-vk-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-vk-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-vk-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-vk-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-vk-typography-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-vk-typography-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-vk-typography-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-vk-icon-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-vk-icon-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-vk-icon-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-vk-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-vk-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-vk-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-telegram-background-filled-color-default-v0-12-2: #27A5E4;
  --bloko-color-button-social-telegram-background-filled-color-hover-v0-12-2: #1B97D5;
  --bloko-color-button-social-telegram-background-filled-color-active-v0-12-2: #1887BE;
  --bloko-color-button-social-telegram-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-telegram-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-telegram-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-telegram-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-telegram-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-telegram-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-telegram-border-filled-color-default-v0-12-2: #0077FF00;
  --bloko-color-button-social-telegram-border-filled-color-hover-v0-12-2: #0077FF00;
  --bloko-color-button-social-telegram-border-filled-color-active-v0-12-2: #0077FF00;
  --bloko-color-button-social-telegram-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-telegram-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-telegram-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-telegram-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-telegram-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-telegram-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-telegram-typography-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-telegram-typography-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-telegram-typography-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-telegram-icon-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-telegram-icon-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-telegram-icon-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-telegram-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-telegram-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-telegram-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-viber-background-filled-color-default-v0-12-2: #7360F2;
  --bloko-color-button-social-viber-background-filled-color-hover-v0-12-2: #5D47F0;
  --bloko-color-button-social-viber-background-filled-color-active-v0-12-2: #4830EE;
  --bloko-color-button-social-viber-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-viber-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-viber-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-viber-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-viber-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-viber-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-viber-border-filled-color-default-v0-12-2: #0077FF00;
  --bloko-color-button-social-viber-border-filled-color-hover-v0-12-2: #0077FF00;
  --bloko-color-button-social-viber-border-filled-color-active-v0-12-2: #0077FF00;
  --bloko-color-button-social-viber-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-viber-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-viber-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-viber-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-viber-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-viber-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-viber-typography-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-viber-typography-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-viber-typography-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-viber-icon-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-viber-icon-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-viber-icon-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-viber-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-viber-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-viber-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-whatsapp-background-filled-color-default-v0-12-2: #69E382;
  --bloko-color-button-social-whatsapp-background-filled-color-hover-v0-12-2: #53DF70;
  --bloko-color-button-social-whatsapp-background-filled-color-active-v0-12-2: #3EDB5E;
  --bloko-color-button-social-whatsapp-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-whatsapp-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-whatsapp-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-whatsapp-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-whatsapp-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-whatsapp-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-whatsapp-border-filled-color-default-v0-12-2: #0077FF00;
  --bloko-color-button-social-whatsapp-border-filled-color-hover-v0-12-2: #0077FF00;
  --bloko-color-button-social-whatsapp-border-filled-color-active-v0-12-2: #0077FF00;
  --bloko-color-button-social-whatsapp-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-whatsapp-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-whatsapp-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-whatsapp-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-whatsapp-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-whatsapp-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-whatsapp-typography-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-whatsapp-typography-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-whatsapp-typography-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-whatsapp-icon-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-whatsapp-icon-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-whatsapp-icon-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-whatsapp-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-whatsapp-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-whatsapp-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-imessage-background-filled-color-default-v0-12-2: #34DA50;
  --bloko-color-button-social-imessage-background-filled-color-hover-v0-12-2: #26CF42;
  --bloko-color-button-social-imessage-background-filled-color-active-v0-12-2: #22B93B;
  --bloko-color-button-social-imessage-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-imessage-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-imessage-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-imessage-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-imessage-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-imessage-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-imessage-border-filled-color-default-v0-12-2: #0077FF00;
  --bloko-color-button-social-imessage-border-filled-color-hover-v0-12-2: #0077FF00;
  --bloko-color-button-social-imessage-border-filled-color-active-v0-12-2: #0077FF00;
  --bloko-color-button-social-imessage-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-imessage-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-imessage-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-imessage-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-imessage-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-imessage-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-imessage-typography-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-imessage-typography-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-imessage-typography-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-imessage-icon-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-imessage-icon-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-imessage-icon-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-imessage-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-imessage-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-imessage-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-google-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-google-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-google-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-google-border-filled-color-default-v0-12-2: #EEF1F700;
  --bloko-color-button-social-google-border-filled-color-hover-v0-12-2: #EEF1F700;
  --bloko-color-button-social-google-border-filled-color-active-v0-12-2: #EEF1F700;
  --bloko-color-button-social-google-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-google-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-google-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-google-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-google-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-google-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-google-background-default-color-default-v0-12-2: #EEF1F7;
  --bloko-color-button-social-google-background-filled-color-hover-v0-12-2: #E0E2E3;
  --bloko-color-button-social-google-background-filled-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-google-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-google-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-google-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-google-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-google-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-google-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-google-typography-filled-color-default-v0-12-2: #2A3137;
  --bloko-color-button-social-google-typography-filled-color-hover-v0-12-2: #2A3137;
  --bloko-color-button-social-google-typography-filled-color-active-v0-12-2: #2A3137;
  --bloko-color-button-social-mail-border-filled-color-default-v0-12-2: #FF9E0000;
  --bloko-color-button-social-mail-border-filled-color-hover-v0-12-2: #FF9E0000;
  --bloko-color-button-social-mail-border-filled-color-active-v0-12-2: #FF9E0000;
  --bloko-color-button-social-mail-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-mail-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-mail-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-mail-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-mail-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-mail-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-mail-background-filled-color-default-v0-12-2: #FF9E00;
  --bloko-color-button-social-mail-background-filled-color-hover-v0-12-2: #F29600;
  --bloko-color-button-social-mail-background-filled-color-active-v0-12-2: #E58E00;
  --bloko-color-button-social-mail-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-mail-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-mail-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-mail-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-mail-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-mail-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-mail-typography-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-mail-typography-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-mail-typography-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-mail-icon-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-mail-icon-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-mail-icon-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-mail-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-mail-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-mail-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-twitter-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-twitter-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-twitter-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-facebook-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-facebook-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-facebook-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-instagram-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-instagram-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-instagram-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-ok-border-filled-color-default-v0-12-2: #F3823100;
  --bloko-color-button-social-ok-border-filled-color-hover-v0-12-2: #F3823100;
  --bloko-color-button-social-ok-border-filled-color-active-v0-12-2: #F3823100;
  --bloko-color-button-social-ok-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-ok-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-ok-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-ok-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-ok-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-ok-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-ok-background-filled-color-default-v0-12-2: #F38231;
  --bloko-color-button-social-ok-background-filled-color-hover-v0-12-2: #E57B2E;
  --bloko-color-button-social-ok-background-filled-color-active-v0-12-2: #D9742C;
  --bloko-color-button-social-ok-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-ok-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-ok-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-ok-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-ok-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-ok-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-ok-typography-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-ok-typography-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-ok-typography-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-ok-icon-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-ok-icon-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-ok-icon-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-ok-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-ok-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-ok-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-dnr-icon-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-dnr-icon-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-dnr-icon-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-dnr-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-dnr-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-dnr-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-dnr-border-filled-color-default-v0-12-2: #2B96CF00;
  --bloko-color-button-social-dnr-border-filled-color-hover-v0-12-2: #2B96CF00;
  --bloko-color-button-social-dnr-border-filled-color-active-v0-12-2: #2B96CF00;
  --bloko-color-button-social-dnr-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-dnr-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-dnr-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-dnr-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-dnr-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-dnr-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-dnr-background-filled-color-default-v0-12-2: #2B96CF;
  --bloko-color-button-social-dnr-background-filled-color-hover-v0-12-2: #288CC2;
  --bloko-color-button-social-dnr-background-filled-color-active-v0-12-2: #2683B5;
  --bloko-color-button-social-dnr-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-dnr-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-dnr-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-dnr-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-dnr-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-dnr-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-dnr-typography-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-dnr-typography-filled-color-hover-v0-12-2: #ffffff;
  --bloko-color-button-social-dnr-typography-filled-color-active-v0-12-2: #ffffff;
  --bloko-color-button-social-esia-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-esia-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-esia-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-esia-border-filled-color-default-v0-12-2: #EEF1F700;
  --bloko-color-button-social-esia-border-filled-color-hover-v0-12-2: #EEF1F700;
  --bloko-color-button-social-esia-border-filled-color-active-v0-12-2: #EEF1F700;
  --bloko-color-button-social-esia-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-esia-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-esia-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-esia-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-esia-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-esia-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-esia-background-filled-color-default-v0-12-2: #EEF1F7;
  --bloko-color-button-social-esia-background-filled-color-hover-v0-12-2: #E0E2E3;
  --bloko-color-button-social-esia-background-filled-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-esia-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-esia-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-esia-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-esia-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-esia-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-esia-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-esia-typography-filled-color-default-v0-12-2: #2A3137;
  --bloko-color-button-social-esia-typography-filled-color-hover-v0-12-2: #2A3137;
  --bloko-color-button-social-esia-typography-filled-color-active-v0-12-2: #2A3137;
  --bloko-color-button-social-tinkoff-icon-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-tinkoff-icon-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-tinkoff-icon-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-tinkoff-border-filled-color-default-v0-12-2: #FADE5600;
  --bloko-color-button-social-tinkoff-border-filled-color-hover-v0-12-2: #FADE5600;
  --bloko-color-button-social-tinkoff-border-filled-color-active-v0-12-2: #FADE5600;
  --bloko-color-button-social-tinkoff-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-tinkoff-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-tinkoff-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-tinkoff-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-tinkoff-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-tinkoff-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-tinkoff-background-filled-color-default-v0-12-2: #FADE56;
  --bloko-color-button-social-tinkoff-background-filled-color-hover-v0-12-2: #EDD352;
  --bloko-color-button-social-tinkoff-background-filled-color-active-v0-12-2: #E0C74D;
  --bloko-color-button-social-tinkoff-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-tinkoff-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-tinkoff-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-tinkoff-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-tinkoff-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-tinkoff-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-tinkoff-typography-filled-color-default-v0-12-2: #2A3137;
  --bloko-color-button-social-tinkoff-typography-filled-color-hover-v0-12-2: #2A3137;
  --bloko-color-button-social-tinkoff-typography-filled-color-active-v0-12-2: #2A3137;
  --bloko-color-button-social-more-border-filled-color-default-v0-12-2: #ffffff00;
  --bloko-color-button-social-more-border-filled-color-hover-v0-12-2: #ffffff00;
  --bloko-color-button-social-more-border-filled-color-active-v0-12-2: #ffffff00;
  --bloko-color-button-social-more-border-outlined-color-default-v0-12-2: #AABBCA;
  --bloko-color-button-social-more-border-outlined-color-hover-v0-12-2: #AABBCA;
  --bloko-color-button-social-more-border-outlined-color-active-v0-12-2: #AABBCA;
  --bloko-color-button-social-more-border-outlined-default-v0-12-2: #CCD5DF;
  --bloko-color-button-social-more-border-outlined-hover-v0-12-2: #CCD5DF;
  --bloko-color-button-social-more-border-outlined-active-v0-12-2: #CCD5DF;
  --bloko-color-button-social-more-background-filled-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-more-background-filled-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-more-background-filled-color-action-v0-12-2: #E6E6E6;
  --bloko-color-button-social-more-background-outlined-color-default-v0-12-2: #ffffff;
  --bloko-color-button-social-more-background-outlined-color-hover-v0-12-2: #F1F1F1;
  --bloko-color-button-social-more-background-outlined-color-active-v0-12-2: #D4D5D6;
  --bloko-color-button-social-more-background-outlined-default-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-more-background-outlined-hover-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-more-background-outlined-active-v0-12-2: #FFFFFF00;
  --bloko-color-button-social-more-typography-filled-color-default-v0-12-2: #2A3137;
  --bloko-color-button-social-more-typography-filled-color-hover-v0-12-2: #2A3137;
  --bloko-color-button-social-more-typography-filled-color-active-v0-12-2: #2A3137;
  --bloko-color-data-entry-border-default-v0-12-2: #AABBCA;
  --bloko-color-data-entry-border-disabled-v0-12-2: #AABBCA80;
  --bloko-color-data-entry-border-hover-v0-12-2: #DCE3EB;
  --bloko-color-data-entry-border-active-v0-12-2: #0070FF;
  --bloko-color-data-entry-border-invalid-v0-12-2: #FF8A77;
  --bloko-color-data-entry-background-default-v0-12-2: #ffffff;
  --bloko-color-data-entry-background-disabled-v0-12-2: #ffffff80;
  --bloko-color-data-entry-background-hover-v0-12-2: #ffffff;
  --bloko-color-data-entry-background-active-v0-12-2: #ffffff;
  --bloko-color-data-entry-background-invalid-v0-12-2: #ffffff;
  --bloko-color-data-entry-icon-default-v0-12-2: #768694;
  --bloko-color-data-entry-icon-disabled-v0-12-2: #8293A280;
  --bloko-color-data-entry-icon-hover-v0-12-2: #6A7885;
  --bloko-color-data-entry-icon-active-v0-12-2: #768694;
  --bloko-color-data-entry-icon-invalid-v0-12-2: #768694;
  --bloko-color-data-entry-typography-default-v0-12-2: #2A3137;
  --bloko-color-data-entry-typography-disabled-v0-12-2: #2A313780;
  --bloko-color-data-entry-typography-hover-v0-12-2: #2A3137;
  --bloko-color-data-entry-typography-active-v0-12-2: #2A3137;
  --bloko-color-data-entry-typography-invalid-v0-12-2: #2A3137;
  --bloko-color-data-entry-placeholder-default-v0-12-2: #768694;
  --bloko-color-data-entry-placeholder-disabled-v0-12-2: #8293A280;
  --bloko-color-data-entry-placeholder-hover-v0-12-2: #768694;
  --bloko-color-data-entry-placeholder-active-v0-12-2: #768694;
  --bloko-color-data-entry-placeholder-invalid-v0-12-2: #768694;
  --bloko-color-checkable-checked-border-default-v0-12-2: #0070FF;
  --bloko-color-checkable-checked-border-disabled-v0-12-2: #0070FF00;
  --bloko-color-checkable-checked-border-hover-v0-12-2: #2B7FFF;
  --bloko-color-checkable-checked-background-default-v0-12-2: #0070FF;
  --bloko-color-checkable-checked-background-disabled-v0-12-2: #0070FF80;
  --bloko-color-checkable-checked-background-hover-v0-12-2: #2B7FFF;
  --bloko-color-checkable-checked-icon-default-v0-12-2: #ffffff;
  --bloko-color-checkable-checked-icon-disabled-v0-12-2: #ffffffCC;
  --bloko-color-checkable-checked-icon-hover-v0-12-2: #ffffff;
  --bloko-color-checkable-default-border-default-v0-12-2: #AABBCA;
  --bloko-color-checkable-default-border-disabled-v0-12-2: #AABBCA80;
  --bloko-color-checkable-default-border-hover-v0-12-2: #DCE3EB;
  --bloko-color-checkable-default-border-invalid-v0-12-2: #FF8A77;
  --bloko-color-checkable-default-background-default-v0-12-2: #ffffff;
  --bloko-color-checkable-default-background-disabled-v0-12-2: #ffffff80;
  --bloko-color-checkable-default-background-hover-v0-12-2: #ffffff;
  --bloko-color-checkable-default-background-invalid-v0-12-2: #ffffff;
  --bloko-color-checkable-typography-default-v0-12-2: #2A3137;
  --bloko-color-checkable-typography-disabled-v0-12-2: #2A313780;
  --bloko-color-checkable-typography-hover-v0-12-2: #2A3137;
  --bloko-color-checkable-typography-invalid-v0-12-2: #2A313780;
  --bloko-color-badge-default-background-default-v0-12-2: #AABBCA;
  --bloko-color-badge-default-background-hover-v0-12-2: #ABABAB;
  --bloko-color-badge-default-background-active-v0-12-2: #9E9E9E;
  --bloko-color-badge-default-border-default-v0-12-2: #ffffff;
  --bloko-color-badge-default-border-hover-v0-12-2: #ffffff;
  --bloko-color-badge-default-border-active-v0-12-2: #ffffff;
  --bloko-color-badge-default-typography-default-v0-12-2: #ffffff;
  --bloko-color-badge-default-typography-hover-v0-12-2: #ffffff;
  --bloko-color-badge-default-typography-active-v0-12-2: #ffffff;
  --bloko-color-badge-primary-typography-default-v0-12-2: #ffffff;
  --bloko-color-badge-primary-typography-hover-v0-12-2: #ffffff;
  --bloko-color-badge-primary-typography-active-v0-12-2: #ffffff;
  --bloko-color-badge-primary-background-default-v0-12-2: #76ADFE;
  --bloko-color-badge-primary-background-hover-v0-12-2: #5E9EFF;
  --bloko-color-badge-primary-background-active-v0-12-2: #468FFD;
  --bloko-color-badge-primary-border-default-v0-12-2: #ffffff;
  --bloko-color-badge-primary-border-hover-v0-12-2: #ffffff;
  --bloko-color-badge-primary-border-active-v0-12-2: #ffffff;
  --bloko-color-badge-tertiary-typography-default-v0-12-2: #ffffff;
  --bloko-color-badge-tertiary-typography-hover-v0-12-2: #ffffff;
  --bloko-color-badge-tertiary-typography-active-v0-12-2: #ffffff;
  --bloko-color-badge-tertiary-background-default-v0-12-2: #000000;
  --bloko-color-badge-tertiary-background-hover-v0-12-2: #000000;
  --bloko-color-badge-tertiary-background-active-v0-12-2: #000000;
  --bloko-color-badge-tertiary-border-default-v0-12-2: #ffffff;
  --bloko-color-badge-tertiary-border-hover-v0-12-2: #ffffff;
  --bloko-color-badge-tertiary-border-active-v0-12-2: #ffffff;
  --bloko-color-badge-success-typography-default-v0-12-2: #ffffff;
  --bloko-color-badge-success-typography-hover-v0-12-2: #ffffff;
  --bloko-color-badge-success-typography-active-v0-12-2: #ffffff;
  --bloko-color-badge-success-border-default-v0-12-2: #ffffff;
  --bloko-color-badge-success-border-hover-v0-12-2: #ffffff;
  --bloko-color-badge-success-border-active-v0-12-2: #ffffff;
  --bloko-color-badge-success-background-default-v0-12-2: #83D99C;
  --bloko-color-badge-success-background-hover-v0-12-2: #5BCF83;
  --bloko-color-badge-success-background-active-v0-12-2: #0DC267;
  --bloko-color-badge-warning-typography-default-v0-12-2: #ffffff;
  --bloko-color-badge-warning-typography-hover-v0-12-2: #ffffff;
  --bloko-color-badge-warning-typography-active-v0-12-2: #ffffff;
  --bloko-color-badge-warning-border-default-v0-12-2: #ffffff;
  --bloko-color-badge-warning-border-hover-v0-12-2: #ffffff;
  --bloko-color-badge-warning-border-active-v0-12-2: #ffffff;
  --bloko-color-badge-warning-background-default-v0-12-2: #FF8A77;
  --bloko-color-badge-warning-background-hover-v0-12-2: #EE4735;
  --bloko-color-badge-warning-background-active-v0-12-2: #FF4D3A;
  --bloko-color-badge-inverse-typography-default-v0-12-2: #000000;
  --bloko-color-badge-inverse-typography-hover-v0-12-2: #000000;
  --bloko-color-badge-inverse-typography-active-v0-12-2: #000000;
  --bloko-color-badge-inverse-border-default-v0-12-2: #000000;
  --bloko-color-badge-inverse-border-hover-v0-12-2: #000000;
  --bloko-color-badge-inverse-border-active-v0-12-2: #000000;
  --bloko-color-badge-inverse-background-default-v0-12-2: #ffffff;
  --bloko-color-badge-inverse-background-hover-v0-12-2: #ffffff;
  --bloko-color-badge-inverse-background-active-v0-12-2: #ffffff;
  --bloko-color-tag-default-background-default-default-v0-12-2: #EEF1F7;
  --bloko-color-tag-default-background-default-disabled-v0-12-2: #EEF1F780;
  --bloko-color-tag-default-background-delete-default-v0-12-2: #EEF1F7;
  --bloko-color-tag-default-background-delete-disabled-v0-12-2: #EEF1F780;
  --bloko-color-tag-default-background-delete-hover-v0-12-2: #E2E2E2;
  --bloko-color-tag-default-background-delete-active-v0-12-2: #D4D4D4;
  --bloko-color-tag-default-background-plus-default-v0-12-2: #0070FF;
  --bloko-color-tag-default-background-plus-disabled-v0-12-2: #0070FF80;
  --bloko-color-tag-default-background-plus-hover-v0-12-2: #2B7FFF;
  --bloko-color-tag-default-background-plus-active-v0-12-2: #0070FF;
  --bloko-color-tag-default-icon-default-default-v0-12-2: #2A3137;
  --bloko-color-tag-default-icon-default-disabled-v0-12-2: #2A3137;
  --bloko-color-tag-default-icon-delete-default-v0-12-2: #2A3137;
  --bloko-color-tag-default-icon-delete-disabled-v0-12-2: #2A3137;
  --bloko-color-tag-default-icon-plus-default-v0-12-2: #ffffff;
  --bloko-color-tag-default-icon-plus-disabled-v0-12-2: #ffffff80;
  --bloko-color-tag-default-typography-default-v0-12-2: #2A3137;
  --bloko-color-tag-default-typography-disabled-v0-12-2: #2A313780;
  --bloko-color-tag-success-icon-default-default-v0-12-2: #0DC267;
  --bloko-color-tag-success-icon-default-disabled-v0-12-2: #0DC26780;
  --bloko-color-tag-success-icon-delete-default-v0-12-2: #2A3137;
  --bloko-color-tag-success-icon-delete-disabled-v0-12-2: #2A313780;
  --bloko-color-tag-success-icon-plus-default-v0-12-2: #ffffff;
  --bloko-color-tag-success-icon-plus-disabled-v0-12-2: #ffffff80;
  --bloko-color-tag-success-background-default-default-v0-12-2: #F0FBF3;
  --bloko-color-tag-success-background-default-disabled-v0-12-2: #F0FBF380;
  --bloko-color-tag-success-background-delete-default-v0-12-2: #F0FBF3;
  --bloko-color-tag-success-background-delete-disabled-v0-12-2: #F0FBF380;
  --bloko-color-tag-success-background-delete-hover-v0-12-2: #E0F6E5;
  --bloko-color-tag-success-background-delete-active-v0-12-2: #C1EDCC;
  --bloko-color-tag-success-background-plus-default-v0-12-2: #0070FF;
  --bloko-color-tag-success-background-plus-disabled-v0-12-2: #0070FF80;
  --bloko-color-tag-success-background-plus-hover-v0-12-2: #2B7FFF;
  --bloko-color-tag-success-background-plus-active-v0-12-2: #0070FF;
  --bloko-color-tag-success-typography-default-v0-12-2: #2A3137;
  --bloko-color-tag-success-typography-disabled-v0-12-2: #2A313780;
  --bloko-typography-button-large-font-family-v0-12-2: Arial;
  --bloko-typography-button-large-font-weight-v0-12-2: Regular;
  --bloko-typography-button-large-line-height-v0-12-2: 150%;
  --bloko-typography-button-large-font-size-v0-12-2: 16px;
  --bloko-typography-button-medium-font-family-v0-12-2: Arial;
  --bloko-typography-button-medium-font-weight-v0-12-2: Regular;
  --bloko-typography-button-medium-line-height-v0-12-2: 150%;
  --bloko-typography-button-medium-font-size-v0-12-2: 14px;
  --bloko-typography-button-small-font-family-v0-12-2: Arial;
  --bloko-typography-button-small-font-weight-v0-12-2: Regular;
  --bloko-typography-button-small-line-height-v0-12-2: 150%;
  --bloko-typography-button-small-font-size-v0-12-2: 14px;
  --bloko-typography-tag-default-font-family-v0-12-2: Arial;
  --bloko-typography-tag-default-font-weight-v0-12-2: Regular;
  --bloko-typography-tag-default-line-height-v0-12-2: 150%;
  --bloko-typography-tag-default-font-size-v0-12-2: 14px;
  --bloko-typography-data-entry-large-font-family-v0-12-2: Arial;
  --bloko-typography-data-entry-large-font-weight-v0-12-2: Regular;
  --bloko-typography-data-entry-large-line-height-v0-12-2: 150%;
  --bloko-typography-data-entry-large-font-size-v0-12-2: 16px;
  --bloko-typography-data-entry-medium-font-family-v0-12-2: Arial;
  --bloko-typography-data-entry-medium-font-weight-v0-12-2: Regular;
  --bloko-typography-data-entry-medium-line-height-v0-12-2: 150%;
  --bloko-typography-data-entry-medium-font-size-v0-12-2: 14px;
  --bloko-typography-data-entry-small-font-family-v0-12-2: Arial;
  --bloko-typography-data-entry-small-font-weight-v0-12-2: Regular;
  --bloko-typography-data-entry-small-line-height-v0-12-2: 150%;
  --bloko-typography-data-entry-small-font-size-v0-12-2: 14px;
  --bloko-border-radius-button-v0-12-2: 4px;
  --bloko-border-radius-tag-v0-12-2: 4px;
  --bloko-border-radius-data-entry-v0-12-2: 4px;
  --bloko-border-radius-checkable-v0-12-2: 4px;
  --bloko-border-radius-badge-v0-12-2: 12px;
}
/**
 * Do not edit directly
 * Generated on Mon, 19 Feb 2024 06:49:45 GMT
 */
:root {
  --bloko-base-size-0-x-v0-12-2: 0px;
  --bloko-base-size-1-x-v0-12-2: 4px;
  --bloko-base-size-2-x-v0-12-2: 8px;
  --bloko-base-size-3-x-v0-12-2: 12px;
  --bloko-base-size-4-x-v0-12-2: 16px;
  --bloko-base-size-5-x-v0-12-2: 20px;
  --bloko-base-size-6-x-v0-12-2: 24px;
  --bloko-base-size-7-x-v0-12-2: 28px;
  --bloko-base-size-8-x-v0-12-2: 32px;
  --bloko-base-size-9-x-v0-12-2: 36px;
  --bloko-base-size-10-x-v0-12-2: 40px;
  --bloko-base-size-11-x-v0-12-2: 44px;
  --bloko-base-size-13-x-v0-12-2: 52px;
  --bloko-base-size-12-x-v0-12-2: 48px;
  --bloko-base-border-radius-0-x-v0-12-2: 0px;
  --bloko-base-border-radius-1-x-v0-12-2: 4px;
  --bloko-base-border-radius-2-x-v0-12-2: 8px;
  --bloko-base-border-radius-3-x-v0-12-2: 12px;
  --bloko-base-space-0-x-v0-12-2: 0px;
  --bloko-base-space-1-x-v0-12-2: 4px;
  --bloko-base-space-2-x-v0-12-2: 8px;
  --bloko-base-space-3-x-v0-12-2: 12px;
  --bloko-base-space-4-x-v0-12-2: 16px;
  --bloko-base-space-5-x-v0-12-2: 20px;
  --bloko-base-space-6-x-v0-12-2: 24px;
  --bloko-base-space-7-x-v0-12-2: 28px;
  --bloko-base-space-8-x-v0-12-2: 32px;
  --bloko-base-space-9-x-v0-12-2: 36px;
  --bloko-base-color-base-white-v0-12-2: #ffffff;
  --bloko-base-color-base-black-v0-12-2: #000000;
  --bloko-base-color-base-special-red-branded-v0-12-2: #d6001d;
  --bloko-base-color-base-special-black-jack-v0-12-2: #20262B;
  --bloko-base-color-base-gray-10-v0-12-2: #F4F6FB;
  --bloko-base-color-base-gray-20-v0-12-2: #EEF1F7;
  --bloko-base-color-base-gray-30-v0-12-2: #CCD5DF;
  --bloko-base-color-base-gray-40-v0-12-2: #AABBCA;
  --bloko-base-color-base-gray-50-v0-12-2: #768694;
  --bloko-base-color-base-gray-60-v0-12-2: #5E6C77;
  --bloko-base-color-base-gray-70-v0-12-2: #3F4850;
  --bloko-base-color-base-gray-80-v0-12-2: #2A3137;
  --bloko-base-color-base-red-10-v0-12-2: #FDEDE9;
  --bloko-base-color-base-red-20-v0-12-2: #FDB5A7;
  --bloko-base-color-base-red-30-v0-12-2: #FF9F8F;
  --bloko-base-color-base-red-40-v0-12-2: #FF8A77;
  --bloko-base-color-base-red-50-v0-12-2: #FE725F;
  --bloko-base-color-base-red-60-v0-12-2: #FF4D3A;
  --bloko-base-color-base-red-70-v0-12-2: #D64030;
  --bloko-base-color-base-red-80-v0-12-2: #AC3225;
  --bloko-base-color-base-yellow-10-v0-12-2: #FDF8E7;
  --bloko-base-color-base-yellow-20-v0-12-2: #FDF0C7;
  --bloko-base-color-base-yellow-30-v0-12-2: #F9E193;
  --bloko-base-color-base-yellow-40-v0-12-2: #F7D044;
  --bloko-base-color-base-yellow-50-v0-12-2: #E8C33D;
  --bloko-base-color-base-yellow-60-v0-12-2: #D9B63A;
  --bloko-base-color-base-yellow-70-v0-12-2: #C8A836;
  --bloko-base-color-base-yellow-80-v0-12-2: #7E691F;
  --bloko-base-color-base-orange-10-v0-12-2: #FFEEDD;
  --bloko-base-color-base-orange-20-v0-12-2: #FFDDBB;
  --bloko-base-color-base-orange-30-v0-12-2: #FECB97;
  --bloko-base-color-base-orange-40-v0-12-2: #FDB972;
  --bloko-base-color-base-orange-50-v0-12-2: #FFA439;
  --bloko-base-color-base-orange-60-v0-12-2: #FF9900;
  --bloko-base-color-base-orange-70-v0-12-2: #BF7104;
  --bloko-base-color-base-orange-80-v0-12-2: #8A5100;
  --bloko-base-color-base-blue-10-v0-12-2: #E7F2FF;
  --bloko-base-color-base-blue-20-v0-12-2: #D1E4FE;
  --bloko-base-color-base-blue-30-v0-12-2: #A4C9FE;
  --bloko-base-color-base-blue-40-v0-12-2: #76ADFE;
  --bloko-base-color-base-blue-50-v0-12-2: #468FFD;
  --bloko-base-color-base-blue-60-v0-12-2: #0070FF;
  --bloko-base-color-base-blue-70-v0-12-2: #0058CC;
  --bloko-base-color-base-blue-80-v0-12-2: #00429B;
  --bloko-base-color-base-violet-10-v0-12-2: #F3EEFF;
  --bloko-base-color-base-violet-20-v0-12-2: #DBCDFF;
  --bloko-base-color-base-violet-30-v0-12-2: #AF8BF5;
  --bloko-base-color-base-violet-40-v0-12-2: #9A67EF;
  --bloko-base-color-base-violet-50-v0-12-2: #9054EA;
  --bloko-base-color-base-violet-60-v0-12-2: #883BE9;
  --bloko-base-color-base-violet-70-v0-12-2: #6A2EB7;
  --bloko-base-color-base-violet-80-v0-12-2: #4E1E8B;
  --bloko-base-color-base-green-10-v0-12-2: #F0FBF3;
  --bloko-base-color-base-green-20-v0-12-2: #E0F6E5;
  --bloko-base-color-base-green-30-v0-12-2: #A5E2B6;
  --bloko-base-color-base-green-40-v0-12-2: #83D99C;
  --bloko-base-color-base-green-50-v0-12-2: #5BCF83;
  --bloko-base-color-base-green-60-v0-12-2: #0DC267;
  --bloko-base-color-base-green-70-v0-12-2: #0EA658;
  --bloko-base-color-base-green-80-v0-12-2: #0D8847;
  --bloko-base-color-social-vk-v0-12-2: #0077FF;
  --bloko-base-color-social-mail-v0-12-2: #FF9E00;
  --bloko-base-color-social-facebook-v0-12-2: #177AE6;
  --bloko-base-color-social-ok-v0-12-2: #F38231;
  --bloko-base-color-social-dnr-v0-12-2: #2B96CF;
  --bloko-base-color-social-telegram-v0-12-2: #27A5E4;
  --bloko-base-color-social-viber-v0-12-2: #7360F2;
  --bloko-base-color-social-whatsapp-v0-12-2: #69E382;
  --bloko-base-color-social-twitter-v0-12-2: #01C0FF;
  --bloko-base-color-social-skype-v0-12-2: #00B0F0;
  --bloko-base-color-social-fl-v0-12-2: #00CD5E;
  --bloko-base-color-social-icq-v0-12-2: #60D184;
  --bloko-base-color-social-linkedin-v0-12-2: #2A66BC;
  --bloko-base-color-social-yandex-v0-12-2: #FC3F1D;
  --bloko-base-color-social-android-v0-12-2: #3DDC84;
  --bloko-base-color-social-tinkoff-v0-12-2: #FADE56;
  --bloko-base-color-social-imessage-v0-12-2: #34DA50;
  --bloko-base-shadow-level-3-color-v0-12-2: #7090b052;
  --bloko-base-shadow-level-3-type-v0-12-2: dropShadow;
  --bloko-base-shadow-level-3-x-v0-12-2: 0;
  --bloko-base-shadow-level-3-y-v0-12-2: 12;
  --bloko-base-shadow-level-3-blur-v0-12-2: 24;
  --bloko-base-shadow-level-3-spread-v0-12-2: 0;
  --bloko-base-shadow-level-2-color-v0-12-2: #7090b03d;
  --bloko-base-shadow-level-2-type-v0-12-2: dropShadow;
  --bloko-base-shadow-level-2-x-v0-12-2: 0;
  --bloko-base-shadow-level-2-y-v0-12-2: 8;
  --bloko-base-shadow-level-2-blur-v0-12-2: 16;
  --bloko-base-shadow-level-2-spread-v0-12-2: 0;
  --bloko-base-shadow-level-1-color-v0-12-2: #7090b029;
  --bloko-base-shadow-level-1-type-v0-12-2: dropShadow;
  --bloko-base-shadow-level-1-x-v0-12-2: 0;
  --bloko-base-shadow-level-1-y-v0-12-2: 4;
  --bloko-base-shadow-level-1-blur-v0-12-2: 12;
  --bloko-base-shadow-level-1-spread-v0-12-2: 0;
}
.bloko-zp-bright {
  --bloko-color-button-primary-typography-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-primary-typography-outlined-default-v0-12-2: #7195af;
  --bloko-color-button-primary-typography-outlined-disabled-v0-12-2: #7195af80;
  --bloko-color-button-primary-typography-outlined-hover-v0-12-2: #617f96;
  --bloko-color-button-primary-typography-outlined-active-v0-12-2: #506a7c;
  --bloko-color-button-primary-background-filled-default-v0-12-2: #2A3137;
  --bloko-color-button-primary-background-filled-hover-v0-12-2: #18191a;
  --bloko-color-button-primary-background-filled-active-v0-12-2: #000;
  --bloko-color-button-primary-background-filled-disabled-v0-12-2: #2A313780;
  --bloko-color-button-primary-background-outlined-default-v0-12-2: #ffffff00;
  --bloko-color-button-primary-background-outlined-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-primary-background-outlined-hover-v0-12-2: #2B7FFF;
  --bloko-color-button-primary-background-outlined-active-v0-12-2: #0070FF;
  --bloko-color-button-primary-border-filled-default-v0-12-2: #2A3137;
  --bloko-color-button-primary-border-filled-disabled-v0-12-2: #2A313700;
  --bloko-color-button-primary-border-filled-hover-v0-12-2: #18191a;
  --bloko-color-button-primary-border-filled-active-v0-12-2: #18191a;
  --bloko-color-button-primary-border-outlined-default-v0-12-2: #7195af;
  --bloko-color-button-primary-border-outlined-disabled-v0-12-2: #7195af80;
  --bloko-color-button-primary-border-outlined-hover-v0-12-2: #617f96;
  --bloko-color-button-primary-border-outlined-active-v0-12-2: #506a7c;
}
.bloko-zp {
  --bloko-color-button-primary-typography-filled-default-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-filled-hover-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-filled-active-v0-12-2: #ffffff;
  --bloko-color-button-primary-typography-filled-disabled-v0-12-2: #ffffffCC;
  --bloko-color-button-primary-typography-outlined-default-v0-12-2: #7195af;
  --bloko-color-button-primary-typography-outlined-disabled-v0-12-2: #7195af80;
  --bloko-color-button-primary-typography-outlined-hover-v0-12-2: #76ADFE;
  --bloko-color-button-primary-typography-outlined-active-v0-12-2: #76ADFE;
  --bloko-color-button-primary-background-filled-default-v0-12-2: #7195af;
  --bloko-color-button-primary-background-filled-hover-v0-12-2: #617f96;
  --bloko-color-button-primary-background-filled-active-v0-12-2: #506a7c;
  --bloko-color-button-primary-background-filled-disabled-v0-12-2: #7195af80;
  --bloko-color-button-primary-background-outlined-default-v0-12-2: #ffffff00;
  --bloko-color-button-primary-background-outlined-disabled-v0-12-2: #ffffff00;
  --bloko-color-button-primary-background-outlined-hover-v0-12-2: #E2E2E2;
  --bloko-color-button-primary-background-outlined-active-v0-12-2: #E2E2E2;
  --bloko-color-button-primary-border-filled-default-v0-12-2: #7195af;
  --bloko-color-button-primary-border-filled-disabled-v0-12-2: #7195af00;
  --bloko-color-button-primary-border-filled-hover-v0-12-2: #617f96;
  --bloko-color-button-primary-border-filled-active-v0-12-2: #617f96;
  --bloko-color-button-primary-border-outlined-default-v0-12-2: #7195af;
  --bloko-color-button-primary-border-outlined-disabled-v0-12-2: #7195af80;
  --bloko-color-button-primary-border-outlined-hover-v0-12-2: #76ADFE;
  --bloko-color-button-primary-border-outlined-active-v0-12-2: #76ADFE;
  --bloko-color-checkable-checked-border-default-v0-12-2: #ffe000;
  --bloko-color-checkable-checked-border-disabled-v0-12-2: #ffe00000;
  --bloko-color-checkable-checked-border-hover-v0-12-2: #fff600;
  --bloko-color-checkable-checked-background-default-v0-12-2: #ffe000;
  --bloko-color-checkable-checked-background-disabled-v0-12-2: #ffe00080;
  --bloko-color-checkable-checked-background-hover-v0-12-2: #fff600;
  --bloko-color-checkable-checked-icon-default-v0-12-2: #2A3137;
  --bloko-color-checkable-checked-icon-disabled-v0-12-2: #2A3137CC;
  --bloko-color-checkable-checked-icon-hover-v0-12-2: #2A3137;
}
/* stylelint-disable selector-nested-pattern */
/* stylelint-enable */
.bloko-hh {
  --bloko-radio-color-border-focus: #0070FF;
  --bloko-checkbox-color-image-offset: 0;
  --bloko-checkbox-label-line-height: 1.43;
  --bloko-checkable-control-vertical-indent: 2px;
  --bloko-checkable-control-size: 20px;
  --bloko-form-size-baseline-first: 8px;
  --bloko-checkable-control-margin-right: 8px;
  --bloko-radio-width-border-checked: 6px;
}
:root {
  --bloko-radio-color-border-focus: #0070FF;
  --bloko-checkbox-color-image-offset: 0;
  --bloko-checkbox-label-line-height: 1.43;
  --bloko-checkable-control-vertical-indent: 2px;
  --bloko-checkable-control-size: 20px;
  --bloko-form-size-baseline-first: 8px;
  --bloko-checkable-control-margin-right: 8px;
  --bloko-radio-width-border-checked: 6px;
}
.bloko-zp {
  --bloko-radio-color-border-focus: #ffe000;
  --bloko-checkbox-color-image-offset: -20px;
}
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
html,
body {
  background-color: #ffffff;
}
body {
  color: #2A3137;
  font-family: var(--bloko-font-family-override, Arial, 'Helvetica Neue', sans-serif);
  line-height: 1.43;
  font-size: 14px;
}
svg:not(:root) {
  overflow: hidden;
}
button,
input,
select,
textarea {
  margin: 0;
  width: auto;
  box-sizing: border-box;
  font-family: var(--bloko-font-family-override, Arial, 'Helvetica Neue', sans-serif);
  /* stylelint-disable-next-line declaration-property-value-allowed-list */
  font-size: 14px;
}
textarea::placeholder {
  text-indent: 0;
}
input[type='button'],
input[type='reset'],
input[type='submit'],
input[type='image'],
button {
  cursor: pointer;
  overflow: visible;
}
input[type='button']::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner,
input[type='image']::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  empty-cells: show;
  border-spacing: 0;
}
td {
  vertical-align: top;
}
ul {
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}
:global .g-notransition {
  /* stylelint-disable-next-line declaration-no-important */
  transition: none !important;
}
:global .g-hidden {
  /* stylelint-disable-next-line declaration-no-important */
  display: none !important;
  visibility: hidden;
}
:global .g-iframefix {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: 0;
}
:global .scroll-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
:global .g-anim-fade {
  opacity: 0;
  transition: opacity 150ms linear;
}
:global .g-anim-fade_in {
  opacity: 1;
}
/* stylelint-disable hhru/less-variable-value-disallowed-list */
/* Design System colors */
/* Red */
/* Yellow */
/* Orange */
/* Blue */
/* Violet */
/* Green */
/* Grayscale */
/* Special */
/*
    Необходимо для работы компонента common/media.js. Используем font-family, так как это текстовое свойство
    и работает не только у псевдоэлементов (в отличие от content)
*/
/* stylelint-disable declaration-property-value-allowed-list,
font-family-no-missing-generic-family-keyword */
.bloko-media-marker {
  display: none;
  font-family: m;
}
@media (min-width: 1340px) {
  .bloko-media-marker {
    font-family: l;
  }
}
@media (max-width: 1019px) {
  :global(.xs-friendly) .bloko-media-marker,
  :global(.s-friendly) .bloko-media-marker {
    font-family: s;
  }
}
@media (max-width: 699px) {
  :global(.xs-friendly) .bloko-media-marker {
    font-family: xs;
  }
}
/* stylelint-enable */
