@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';
@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-ui/breakpoints';

.container {
    position: relative;
    background-color: @magritte-color-background-accent-secondary;
    padding: @magritte-static-size-600;
    text-align: center;
    border-radius: 12px;
    height: 264px;
    background-repeat: no-repeat;
    background-position: bottom center;
    box-sizing: border-box;
}

.container.dreamjob-default {
    background-image: url('./images/dreamjob-bg-default.svg');

    .screen-gt-s({
        background-image: url('./images/dreamjob-bench-bg-default.svg');
    });
}

.container.dreamjob-success {
    background-image: url('./images/dreamjob-bg-success.svg');

    .screen-gt-s({
        background-image: url('./images/dreamjob-bench-bg-success.svg');
    });
}

.container.enable-reviews {
    background-image: url('./images/enable-reviews-bg.svg');
}

.container.trial-extension {
    background-color: @magritte-color-background-negative-secondary;
    background-image: url('./images/dreamjob-bg-negative.svg');

    .screen-gt-s({
        background-image: url('./images/dreamjob-bench-bg-negative.svg');
    });
}

.container.without-reviews {
    background-image: url('./images/without-reviews-bg.svg');
}

.container.submit-application {
    background-image: url('./images/submit-application-bg.svg');
}

.title {
    display: flex;
    gap: @magritte-static-size-300;
    align-items: center;
    justify-content: center;
}

.image {
    background-image: url('./images/blue-vector.svg');
    width: 32px;
    height: 28px;
}
