@import '~bloko/common/styles/media/_screen';

.root {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;

    @media @screen-gt-xs {
        flex-direction: row;
    }
}

.image {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-image: url('./hire_faster_slogan.svg');

    width: 290px;
    height: 240px;

    @media @screen-gt-xs {
        width: 400px;
        height: 330px;
    }
}

.header {
    text-align: center;

    @media @screen-gt-xs {
        text-align: left;
    }
}

.slogan {
    max-width: 400px;

    @media @screen-gt-xs {
        max-width: 290px;
    }

    @media @screen-gt-s {
        max-width: 500px;
    }
}

.icon {
    margin-left: 4px;
    vertical-align: bottom;
    cursor: pointer;
}
