@import '~bloko/common/styles/colors';

.points-container {
    background: @color-gray-10;
    padding: 10px;
    border-radius: 4px;
}

.points-point {
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
}

.points-list {
    list-style-type: disc;
}

.points-list-item {
    margin: 8px 0 0 24px;

    &:first-child {
        margin-top: 0;
    }
}
