@import '~@hh.ru/magritte-ui/breakpoints';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media @screen-magritte-gt-s {
        min-height: 466px;
    }
}

.group {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    @media @screen-magritte-gt-s {
        width: auto;
    }
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 100%;

    @media @screen-magritte-gt-s {
        width: auto;
        min-width: 343px;
    }
}

.label {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}
