.link {
    display: flex;
    justify-content: space-between;
}

.icon {
    display: flex;
    align-items: center;

    cursor: pointer;
}
