@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~styles/vars';

.card {
    background: @color-white;
    box-shadow: @widget-box-shadow;
    border-radius: 8px;
    box-sizing: border-box;
    height: 226px;
    padding: 16px;

    width: 270px;
    margin-right: 15px;

    cursor: pointer;

    flex-shrink: 0;

    @media @screen-gt-xs {
        flex-shrink: 1;
        width: 49%;
        margin: 0 1%;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    @media @screen-gt-m {
        width: 32%;
    }
}

.card_fix-size {
    width: 270px;
    flex-shrink: 0;
}

.card_full-height {
    height: 100%;
}

.card_big-height {
    height: 318px;
    flex-shrink: 0;
}

.card_no-margin {
    margin: 0;
}

.review-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.review-content {
    display: flex;
    flex-direction: column;

    user-select: none;
    height: 100%;
}

.review-content_disabled {
    color: @color-gray-40;
}

.rating-line {
    display: flex;
    flex-direction: row;
    margin-top: 14px;
}

.review-text {
    margin-top: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-break: break-word;
}

.review-date {
    margin-left: 10px;
    color: @color-gray-60;
}

.review-footer {
    margin-top: auto;
}

.rating-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}
