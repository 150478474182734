@import '~bloko/common/styles/colors';

.button {
    border: 1px solid @color-blue-60;
    color: @color-blue-60;
    border-radius: 28px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 6px;
    width: 120px;
    height: 56px;
    cursor: pointer;
}

.button_active {
    background: @color-blue-60;
    color: @color-white;
}

.icon {
    width: 24px;
    height: 24px;
}

.icon_like {
    background: url('./images/Like.svg');
    background-size: cover;
}

.icon_dislike {
    background: url('./images/Dislike.svg');
    background-size: cover;
}

.icon_invert {
    background-position: -100%;
}
