@import '~bloko/common/styles/media/_screen';

.root {
    width: 100%;

    @media @screen-gt-s {
        margin: 5px 0;
    }

    @media @screen-gt-m {
        margin-left: auto;
        width: auto;
    }
}
