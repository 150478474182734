.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    overflow: hidden;

    /* stylelint-disable-next-line scss/selector-no-redundant-nesting-selector */
    [class*='magritte-modal-content']:has(&) {
        max-height: 570px;
    }
}
