.container {
    display: flex;
    align-self: center;
    width: 100%;
}

.artwork {
    width: 350px;
    height: 200px;
    background: url('./finishArtwork.svg');
    background-repeat: no-repeat;
    margin: 0 auto;
}
