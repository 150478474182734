@import '~bloko/common/styles/colors';

.link {
    color: @color-blue-70;

    &:hover,
    &:focus,
    &:active {
        color: @color-red-60;
    }
}

.stretched-helper {
    width: 100%;
}
