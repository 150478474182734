@import '~bloko/common/styles/colors';

.root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.card-logo {
    flex-grow: 1;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    color: @color-gray-80;
}
