.container {
    display: flex;
    width: 116px;
    justify-content: space-between;
    align-items: center;
}

.container_micro {
    width: 78px;
}

.container_small {
    width: 88px;
}

.container_big {
    width: 152px;
}

.container_active {
    cursor: pointer;
}
