@import (reference) '~@hh.ru/magritte-ui/breakpoints';

.rate-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-mark {
    width: 16px;
    height: 16px;

    background-image: url('./images/rate-question.svg');
    background-size: cover;
}

.rate-label-wrapper {
    display: flex;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: auto;

    .screen-gt-s({
        min-height: 412px;
    });
}

.tooltip-activator {
    display: flex;
    justify-content: center;
    align-items: center;
}
