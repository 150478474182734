@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~@hh.ru/magritte-ui/tokens';
@import '~@hh.ru/magritte-ui/breakpoints';

.summary {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding: 12px;
    box-sizing: border-box;
}

.summary-item {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 4px;

    @media @screen-magritte-gt-s {
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        border-left: 1px solid @magritte-color-component-divider-stroke-content;

        &:first-child {
            /* stylelint-disable-next-line declaration-property-value-disallowed-list */
            border-left: none;
        }
    }
}

.rating-star-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    margin-top: 16px;
    margin-bottom: 24px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    row-gap: 25px;
    column-gap: 50px;

    @media @screen-gt-m {
        width: auto;
        margin-top: 24px;
    }
}

.summary-no-reviews {
    width: 280px;
    margin-right: 20px;
}

.value {
    color: @color-blue-60;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.comment {
    margin-left: 8px;
}

.open-employer-container {
    @media @screen-gt-s {
        margin: 5px 0;
    }
}

.open-employer {
    background-image: url('./vector.svg');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;
    height: 28px;
    width: 25px;

    @media @screen-gt-xs {
        height: 35px;
        width: 31px;
    }
}

.open-employer-header {
    color: @color-gray-40;
    margin-bottom: 3px;
}
