@import '~bloko/common/styles/colors';

.container {
    display: flex;
    flex-direction: row;
    gap: 0.6%;
}

.step {
    flex: 1;
    background: @color-gray-20;
    height: 8px;

    &:first-child {
        border-radius: 4px 0 0 4px;
    }

    &:last-child {
        border-radius: 0 4px 4px 0;
    }
}

.step_completed-low {
    background: @color-red-60;
}

.step_completed-med {
    background: @color-orange-60;
}

.step_completed-high {
    background: @color-green-60;
}
