@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';

.root {
    box-sizing: border-box;
}

.background {
    position: absolute;

    background-color: @color-gray-10;
    left: 0;
    width: 100%;
    margin-top: 60px;
    height: 430px;

    @media @screen-gt-xs {
        margin-top: 0;
        height: 500px;
    }
}

.background_no-reviews {
    height: 520px;

    @media @screen-gt-xs {
        height: 500px;
    }
}

.company-card-wrapper {
    margin: 24px 20px 20px;
    min-height: 186px;
    float: left;
}

.header {
    margin-top: 100px;
    position: relative;
    text-align: center;

    @media @screen-gt-xs {
        margin-top: 80px;
    }
}

.reviews-instruction-wrapper {
    position: relative;
}
