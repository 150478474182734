@import '~bloko/common/styles/media/_screen';

.root {
    display: flex;
    flex-direction: column;
    margin: 0 10px;

    @media @screen-gt-xs {
        flex-direction: row;
        margin: 0;
    }
}

.description {
    margin-top: 36px;

    @media @screen-gt-xs {
        margin-left: 50px;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.open-instruction {
    width: 200px;
}

.cards {
    margin: 0 auto;
    display: flex;

    @media @screen-gt-xs {
        margin: 0;
    }
}

.second-card {
    display: none;

    @media @screen-gt-s {
        display: block;
        margin-left: 5px;
    }
}
