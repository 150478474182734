@import '~bloko/common/styles/colors';

.tags {
    display: flex;
    gap: 12px;
}

// TODO: Когда появится компонент в блоко, заменить
.tag {
    padding: 2px 8px;
    border-radius: 4px;
}

.answered {
    background-color: @color-green-10;
}

.pinned {
    background-color: @color-gray-20;
}
