@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.root {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background: @color-gray-10;
    border-radius: 8px;

    padding: 24px 16px;

    &:not(:last-child) {
        margin-bottom: 16px;

        @media @screen-gt-s {
            margin-right: 16px;
            margin-bottom: 0;
        }
    }

    @media @screen-gt-xs {
        padding: 24px;
        width: 100%;
        margin-left: 0;
    }

    @media @screen-gt-s {
        width: 400px;
    }
}

.actions {
    margin-top: auto;
}
