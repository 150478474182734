@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.container {
    padding: 1px;
    --gap: 20px;
}

.card {
    background: @color-blue-10;
    border-radius: 4px;
    padding: 20px;
}

.modal-content-review {
    max-width: 740px;

    @media @screen-gt-s {
        min-width: 740px;
    }
}

.modal-content-review > p {
    margin-bottom: 0;
}

.advantages {
    display: flex;
    column-gap: var(--gap);
    flex-wrap: wrap;

    @media @screen-lt-s {
        gap: 0;
        flex-direction: column;
    }
}

.link-button {
    display: inline;
    color: @color-blue-60;
    cursor: pointer;
}
