@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~styles/vars';

.container {
    width: 100%;
}

.top {
    display: flex;
    flex-direction: column;

    @media @screen-gt-m {
        flex-direction: row;
        justify-content: space-between;
    }
}

.leave-review {
    @media @screen-gt-m {
        width: 400px;
        padding: 24px;
        box-shadow: @widget-box-shadow;
        border-radius: 10px;
        background: url('./images/back-plate.svg') right bottom no-repeat;
    }
}

.leave-review_no-verified-phone {
    background: none;
}

.separator {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 40px;
}

.all-reviews-separator-link {
    display: none;

    @media @screen-gt-m {
        display: inline;
    }
}

.reviews-container {
    position: relative;
}

.reviews {
    position: relative;
    min-height: 226px;

    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;
    margin: 0 -15px 0 -20px;
    padding: 24px 20px 20px;

    @media @screen-gt-xs {
        overflow: hidden;
    }
}

.reviews-settings-page {
    min-height: 326px;
}

.review-page {
    display: flex;
    flex-direction: row;
    opacity: 1;
    left: 0;
    transition: all 0.2s ease;
    min-height: 226px;

    @media @screen-gt-xs {
        width: 100%;
        position: absolute;
        padding: 0 20px;
        box-sizing: border-box;
    }
}

.review-page_hidden-left {
    @media @screen-gt-xs {
        left: -100%;
        opacity: 0;
    }
}

.review-page_hidden-right {
    @media @screen-gt-xs {
        left: 100%;
        opacity: 0;
    }
}

.review-page_anonymous {
    justify-content: flex-start;
}

.bottom-line {
    margin-top: 12px;

    @media @screen-gt-m {
        display: none;
    }
}

.mail-sending-mobile {
    @media @screen-gt-s {
        display: none;
    }
}
