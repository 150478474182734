@import '~@hh.ru/magritte-ui/breakpoints';

.cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;

    .screen-gt-s({
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
    });
}

.label {
    width: 100%;
    text-align: center;
}
