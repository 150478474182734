@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/rounded-corner-base';

.label {
    border-radius: @rounded-corner-default;
    display: inline-block;
    padding: 2px 8px;
    vertical-align: middle;
    color: @color-gray-80;
}

.label_light-blue {
    background-color: @color-blue-10;
}
