@import '~@hh.ru/magritte-ui/tokens';
@import '~bloko/common/styles/colors';

// Компонент Accordion не прокидывает пропы `style` и `showBorder` во внутренний `Card`
.accordion-wrapper > [class*='magritte-card-with-border'] {
    --magritte-card-border-color: transparent;
    --magritte-card-background-override: @magritte-color-background-accent-secondary;
}

.points-container {
    background: @color-blue-10;
    padding: 10px;
    border-radius: 4px;
}

.points-list {
    list-style-type: disc;
    padding-inline-start: 20px;
}
