@import '~bloko/common/styles/colors';

.chip {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding: 10px 12px;
    border: 1px solid @color-gray-40;
    border-radius: 4px;
    color: @color-gray-40;
    cursor: pointer;
}

.chip_white {
    border-color: @color-white;
    color: @color-white;
}

.chip_disabled {
    pointer-events: none;
    opacity: 0.5;
}

.content {
    min-width: 36px;
    display: flex;
    justify-content: center;
}
