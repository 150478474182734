@import '~bloko/common/styles/media/_screen';

.show-reviews-wrapper {
    width: 100%;
    margin: 0 auto;

    @media @screen-gt-xs {
        width: 300px;
    }
}
