@import '~bloko/common/styles/media/_screen';

.description {
    display: none;

    @media @screen-gt-m {
        display: block;
        margin-bottom: 16px;
        width: 280px;
    }
}

.image {
    @media @screen-gt-m {
        background-image: url('./images/back-plate.svg');
    }
}
