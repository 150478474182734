@import '~bloko/common/styles/media/_screen';

.header {
    text-align: center;
    margin-top: 64px;

    @media @screen-gt-xs {
        margin-top: 120px;
    }
}

.cards {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media @screen-gt-s {
        flex-direction: row;
    }
}
