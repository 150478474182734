.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    padding: 8px 16px;
    box-sizing: border-box;
}

.text {
    margin: 16px 0 20px;
}
