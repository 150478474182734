@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '~styles/vars';

.root {
    display: flex;
    margin: 0 -15px;
    overflow: scroll;
    padding: 16px 0;

    @media @screen-gt-xs {
        margin-left: 0;
        overflow: initial;
        gap: 24px;
    }
}

.review {
    margin-left: 15px;
    height: 260px;

    @media @screen-gt-xs {
        margin-left: 0;
    }
}

.instruction {
    display: flex;
    flex-direction: column;
    background: @color-white;
    position: relative;
    box-shadow: @widget-box-shadow;
    border-radius: 8px;
    box-sizing: border-box;

    padding: 16px;
    width: 100%;
    min-width: 290px;
    margin-right: 15px;

    @media @screen-gt-s {
        padding: 40px 48px;
        margin-right: 0;
    }
}

.description {
    max-width: 430px;
}

.image {
    display: none;
    position: absolute;
    width: 312px;
    height: 257px;
    background: url('./images/people.svg');
    background-size: cover;
    top: -45px;
    right: 40px;

    @media @screen-gt-m {
        display: inline-block;
    }
}

.submit-application {
    margin-top: auto;
}
