@import '~bloko/common/styles/media/_screen';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    text-align: center;

    @media @screen-gt-xs {
        align-items: flex-start;
        justify-content: flex-start;
        height: auto;
        text-align: left;
    }
}

.image-container {
    display: flex;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
}
