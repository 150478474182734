@import '~bloko/common/styles/colors';

.header {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
}

.warning {
    color: @color-red-60;
}
