@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';

.advantage {
    flex: 0 0 calc(33.33% - var(--gap) * 2 / 3);
    padding: 12px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
