@import '~bloko/common/styles/media/_screen';

.modal-content {
    height: 100%;

    @media @screen-gt-xs {
        width: 450px;
        height: auto;
    }
}

.modal-content_wide {
    @media @screen-gt-xs {
        width: 620px;
    }
}
