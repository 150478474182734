@import '@hh.ru/magritte-ui/tokens';

.container,
.content-rating {
    display: flex;
    align-items: center;
}

.container {
    gap: @magritte-static-size-300;
}

.content-rating {
    gap: @magritte-static-size-200;
}

.reviews-link {
    white-space: nowrap;
}
