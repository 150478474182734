@import '~bloko/common/styles/media/_screen';

.container {
    width: 250px;
    margin-left: 40px;

    position: relative;
}

.image {
    background-image: url('./reviews_M.png');
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100%;

    position: absolute;

    left: 229px;
    top: -50px;
    width: 670px;
    height: 376px;

    @media @screen-gt-m {
        background-image: url('./reviews_L.png');
        left: 270px;
        top: -140px;
        width: 925px;
        height: 596px;
    }
}
